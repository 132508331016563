var FidoConstants = require("./FidoConstants");
var PromiseHelpers = require("./PromiseHelpers");
var TypeConverter = require("./TypeConverter");
var Promise = require("./Promise");
var Constants = require("./Constants");
var LoginConstants = require("../LoginPage/LoginConstants");

var w = window;
var n = w.navigator;
var _publicKeyCredential = w.PublicKeyCredential;
var _serverData = w.ServerData;


var _platformKeySuppressedOS = _serverData.fShouldPlatformKeyBeSuppressed;


var PostType = Constants.PostType;
var AllowedIdentitiesType = LoginConstants.AllowedIdentitiesType;


exports.makeCredential = function (serverChallenge, id, membername, displayName, userProfileImageUrl, serverExcludeList, authenticator, rpId)
{
    var excludeListParam = [];

    if (serverExcludeList)
    {
        excludeListParam = serverExcludeList.map(
            function (credentialId)
            {
                return { type: "public-key", id: TypeConverter.base64UrlStringToArrayBuffer(credentialId) };
            });
    }

    
    var supportedKeyAlgorithms = FidoConstants.SupportedKeyAlgorithms.map(
        function (algorithm)
        {
            return { type: "public-key", alg: algorithm };
        });

    var publicKey =
    {
        challenge: TypeConverter.stringToArrayBuffer(serverChallenge),
        rp:
        {
            name: "Microsoft",
            id: rpId
        },
        user:
        {
            id: TypeConverter.base64UrlStringToArrayBuffer(id),
            name: membername,
            displayName: displayName,
            icon: userProfileImageUrl
        },
        pubKeyCredParams: supportedKeyAlgorithms,
        timeout: FidoConstants.Timeout,
        excludeCredentials: excludeListParam,
        authenticatorSelection:
        {
            authenticatorAttachment: authenticator,
            requireResidentKey: true,
            userVerification: "required"
        },
        attestation: "direct",
        extensions:
        {
            
            "hmacCreateSecret": true,

            
            "credentialProtectionPolicy": "userVerificationOptional"
        }
    };

    return n.credentials.create({ publicKey: publicKey });
};


exports.getAssertion = function (serverChallenge, serverAllowList, rpId, useConditionalMediation, enablePasskeyAutofillUI)
{
    var allowListParam = [];

    if (serverAllowList)
    {
        allowListParam = serverAllowList.map(
            function (credentialId)
            {
                return { type: "public-key", id: TypeConverter.base64UrlStringToArrayBuffer(credentialId) };
            });
    }

    var publicKeyCredentialRequestOptions =
    {
        challenge: TypeConverter.stringToArrayBuffer(serverChallenge),
        timeout: FidoConstants.Timeout,
        rpId: rpId,
        allowCredentials: allowListParam,
        userVerification: "required"
    };

    var conditionalMediation = useConditionalMediation ? "conditional" : undefined;

    return enablePasskeyAutofillUI ? n.credentials.get({ publicKey: publicKeyCredentialRequestOptions, mediation: conditionalMediation }) : n.credentials.get({ publicKey: publicKeyCredentialRequestOptions });
};


exports.isPlatformAuthenticatorAvailable = function (skipStandardSupportCheck)
{
    if (_platformKeySuppressedOS || !(_publicKeyCredential))
    {
        return Promise.resolve(null);
    }

    if (skipStandardSupportCheck)
    {
        return Promise.resolve(true);
    }

    return PromiseHelpers.newPromiseWithTimeout(_publicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable, FidoConstants.PromiseTimeout, false);
};


exports.isConditionalMediationAvailable = function ()
{
    if (_publicKeyCredential !== undefined && _publicKeyCredential !== null
        && typeof _publicKeyCredential.isConditionalMediationAvailable === "function")
    {
        return PromiseHelpers.newPromiseWithTimeout(_publicKeyCredential.isConditionalMediationAvailable, FidoConstants.PromiseTimeout, false);
    }

    return Promise.resolve(false);
};


exports.getIdpFromUserHandle = function (userHandle)
{
    var userHandleString = TypeConverter.arrayBufferToString(userHandle);

    
    if (userHandleString.match(/^M.:/))
    {
        return AllowedIdentitiesType.MsaOnly;
    }

    if (userHandleString.match(/^O.:/))
    {
        return AllowedIdentitiesType.AadOnly;
    }
};


exports.postFidoAssertionToIdp = function (setRequestPending, fidoAssertion, crossDomainCanary, sessionId, originalRequest, canaryTokenName, canary, flowTokenName, flowToken, postUrl, onRedirect)
{
    setRequestPending(true);

    var postParams =
    {
        type: PostType.NGC,
        ps: PostType.NGC,
        assertion: fidoAssertion,
        hpgrequestid: sessionId,
        ctx: originalRequest
    };

    if (crossDomainCanary)
    {
        postParams["lmcCanary"] = crossDomainCanary;
    }

    if (canary)
    {
        postParams[canaryTokenName] = canary;
    }

    postParams[flowTokenName] = flowToken;

    onRedirect(postUrl, postParams, false , true );
};